import mainjson from './main';
export default function GetData() {
        return {
        "apitype":"imagefolders",
        "id":"imagelibrary_id",
        "options":["edit","insert","delete"],
        "subidname":"imagelibrary_name",
        "nameSingle":"image folder",
        "nameMultiple":"image folders",
        "extraoptions":[
            {
                "name":"Images",
                "page":"/allimages",
            }
        ],
        "fields":{
            "field1":{
                "name":"Name",
                "field":"imagelibrary_name",
                "type":"TextInput",
                "required":true,
                "list":true,
            }
        }
    }
    
}